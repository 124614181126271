import type { ErrorObject } from '@vuelidate/core';

interface IFlattenErrors {
  [key: string]: Array<string>;
}

interface IGroupError {
  field: string;
  messages: Array<string>;
}

/**
 * @description Backend error type
 */
export interface IErrorsBackendInterface {
  /**
   * @description General error message
   */
  message: string;

  /**
   * @description Additional information
   */
  extra: {
    /**
     * @description Error field or property with array of messages
     */
    [key: string]: Array<string>;
  };
}

/**
 * @description Aggregated error type
 */
export interface IErrorsAggregatedInterface {
  /**
   * @description The field where the error occurred
   */
  field: string | null;

  /**
   * @description Error messages
   */
  messages: Array<string>;
}

const isObject = (value: any) => {
  return typeof value === 'object' && !Array.isArray(value) && value !== null;
};

export const flattenBackendError = (
  input: object,
  reference?: string | number,
  output?: IFlattenErrors,
): IFlattenErrors => {
  output = output || {};
  for (let key in input) {
    const value = input[key as keyof typeof input];
    key = reference ? reference + '.' + key : key;
    if (isObject(value)) {
      flattenBackendError(value, key, output);
    } else {
      output[key as keyof typeof output] = value;
    }
  }
  return output;
};

interface IGroupError {
  field: string;
  messages: Array<string>;
}

export default function errorsAggregator(
  frontendErrors: ErrorObject[] | null,
  backendErrors?: IErrorsBackendInterface | null,
) {
  let aggregatedErrors: IErrorsAggregatedInterface[] = [];

  /**
   * @description Aggregate frontend & backend error in one data type
   * @returns {this}
   */
  function aggregate() {
    if (frontendErrors) {
      aggregatedErrors = frontendErrors.reduce(
        (group: Array<IGroupError>, error: ErrorObject, index: number) => {
          const { $propertyPath, $message } = error;
          group[index] = group[index] || {
            field: $propertyPath,
            messages: [],
          };
          group[index].messages.push(($message as string) || ($message as Ref<string>).value);

          return group;
        },
        [],
      );
    }

    if (backendErrors?.extra) {
      const flatBackendError = flattenBackendError(backendErrors?.extra);

      for (const key in flatBackendError) {
        aggregatedErrors.push({
          field: key,
          messages: [...flatBackendError[key as keyof typeof flatBackendError]],
        });
      }
    }
  }

  /**
   * @description Filter errors by field name
   * @param {string} field
   * @returns {string[]}
   */
  function filter(field: string): Array<string> {
    aggregate();

    const filteredErrors = Object.values(aggregatedErrors).find((item) => item.field === field);

    return filteredErrors ? filteredErrors.messages : [];
  }

  return {
    filter,
  };
}
