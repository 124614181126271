/**
 * @description Класс с набором методов для работы с query-параметрами.
 */
export default class QueryBuilder {
  /**
   * @description Query params
   * @type {Record<string, any>}
   * @private
   */
  private readonly params: Record<string, any>;

  constructor(params?: Record<string, any>) {
    this.params = params ? params : {};

    this.fromData();
  }

  /**
   * @description Set query param with a single value
   * @param {string} name
   * @param value
   * @returns {QueryBuilder}
   * @private
   */
  private setParam(name: string, value: any): QueryBuilder {
    this.params[name] = value;

    return this;
  }

  /**
   * @description Set query param with an array value
   * @param {string} name
   * @param values
   * @returns {QueryBuilder}
   * @private
   */
  private setArrayParam(name: string, values: any[]): QueryBuilder {
    this.params[name] = values.join(',');

    return this;
  }

  /**
   * @description Data for building the query string
   * @returns {QueryBuilder}
   * @private
   */
  private fromData(): QueryBuilder {
    for (const key in this.params) {
      const value = this.params[key];
      Array.isArray(value) ? this.setArrayParam(key, value) : this.setParam(key, value);
    }

    return this;
  }

  /**
   * @description Build the query string
   * @returns {string}
   */
  public buildQueryString(): string {
    return new URLSearchParams(this.params).toString();
  }

  /**
   * @description Build the query object
   * @returns {Record<string, any>}
   */
  public buildQueryObject(): Record<string, any> {
    const entries = new URLSearchParams(this.params).entries();
    const result: Record<string, any> = {};

    for (const [key, value] of entries) {
      result[key] = value;
    }

    return result;
  }

  /**
   * @description Delete empty query params
   * @returns {QueryBuilder}
   */
  public clearQuery(): QueryBuilder {
    for (const prop in this.params) {
      if (
        this.params[prop as keyof typeof this.params] === null ||
        this.params[prop as keyof typeof this.params] === undefined ||
        this.params[prop as keyof typeof this.params] === '' ||
        (Array.isArray(this.params[prop as keyof typeof this.params]) &&
          this.params[prop as keyof typeof this.params].length === 0)
      ) {
        delete this.params[prop as keyof typeof this.params];
      }
    }

    return this;
  }
}
