import { ref } from 'vue';
import useGetData from '~/composables/api/useGetData';

/**
 * @description Объект для идентификации капчи.
 */
export interface IUseCaptchaResponse {
  /**
   * @description Изображение капчи.
   */
  image: string;

  /**
   * @description Ключ капчи.
   */
  captcha_key: string;
}

export interface IUseCaptchaReturn {
  /**
   * @description Определяет, включена ли капча на сайте (на текущий момент капча включается и выключается глобально,
   * для всех форм).
   */
  isCaptchaEnable: Ref<boolean>;

  /**
   * @description Изображение капчи.
   */
  captchaImage: Ref<string | null>;

  /**
   * @description Ключ капчи.
   */
  captchaKey: Ref<string | null>;

  /**
   * @description Загрузка капчи.
   * @returns {Promise<void>}
   */
  fetchCaptcha: () => Promise<void>;
}

/**
 * @description Composable для работы с функционалом капчи.
 * @param {string} formKey - Название формы.
 * @returns {Promise<IUseCaptchaReturn>}
 */
export default async function useCaptcha(formKey: string): Promise<IUseCaptchaReturn> {
  const { baseApiUrl } = useGetData();
  const isCaptchaEnable = useState<boolean>('captcha');

  const captchaImage = ref<string | null>(null);
  const captchaKey = ref<string | null>(null);

  const fetchCaptcha = async (): Promise<void> => {
    if (!isCaptchaEnable.value) return;

    const response: IUseCaptchaResponse = await $fetch(`captcha/form?form=${formKey}`, {
      baseURL: baseApiUrl,
      method: 'GET',
    });

    captchaImage.value = response.image;
    captchaKey.value = response.captcha_key;
  };

  onMounted(async () => await fetchCaptcha());

  return {
    isCaptchaEnable,
    captchaImage,
    captchaKey,
    fetchCaptcha,
  };
}
